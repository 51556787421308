@import "../../scss/settings";
@import "../../scss/mixins";

.mainWrap {
  display: flex;
  width: 100%;
  height: calc(100vh - 24px);
  overflow: auto;
  position: relative;
  z-index: 10;
  border-radius: 19px;
  @include media-max($lg-break) {
    min-height: 660px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 10px;
  width: 205px;
  height: 46px;
  padding: 2px;
  background: rgba(243, 245, 249, 0.8);
  box-shadow: 0px 17.7273px 18.9091px rgba(0, 20, 170, 0.03);
  border-radius: 26px;
  margin-bottom: 45px;
  margin-top: 10px;
  @include media-max($lg-break) {
    margin-bottom: 25px;
  }
  @include media-max($md-break) {
    margin-bottom: 0;
  }
  @include media-max($xs-break) {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

.infoBlockSmall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2px;
  box-shadow: 0px 17.7273px 18.9091px rgba(0, 20, 170, 0.03);
  z-index: 2;
  background: transparent;
  width: 65px;
  height: 13px;
  border-radius: 0;
}

.infoIcon {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 53px;
  height: 52px;
}

.infoIconSmall {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 13px;
  height: 10px;
}

.infoText {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  height: 100%;
  width: calc(100% - 53px);
  font-size: 12px;
  line-height: 1.2;
  color: $darkText;

}

.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 600px;
  //height: 850px;
  width: 100%;
  //overflow: hidden;
  @include media-max($lg-break) {
    align-items: center;
    margin-bottom: 60px;
    height: 470px;
  }
  @include media-max($md-break) {
    align-items: center;
    margin-bottom: 40px;
  }
  @include media-max($xs-break) {
    height: 390px;
  }
}

.cardWrap {
  width: 338px;
  height: 618px;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  z-index: 1;
  max-width: 100%;
  max-height: 625px;
  position: relative;
  top: 0;
  border-radius: 20px;
  background-image: url("../../image/svg/WatchAnswer/WatchVioletPhone.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(4px);

  //transform: rotateY(0deg);
  //transition: transform 2s 0s;
  @include media-max(1440) {
    width: 311px;
    height: 569px;
  }
  @include media-max($lg-break) {
    width: 244px;
    height: 446px;
  }
  @include media-max($xs-break) {
    width: 200px;
    height: 366px;
  }
}

.cardLeft {
  position: absolute;
  left: -16em;
  @include media-max($lg-break) {
    left: -11em;
  }
  @include media-max($md-break) {
    left: -13em;
  }
  @include media-max($sm-break) {
    left: -10em;
  }

}

.cardRight {
  position: absolute;
  top: 0;
  //background-color: $themeColorOrange;
  //background-image: none;
  //right: -16em;
  //@include media-max($lg-break) {
  //  right: -11em;
  //}
  //@include media-max($md-break) {
  //  right: -13em;
  //}
  //@include media-max($sm-break) {
  //  right: -10em;
  //}

}

.cardContainer {
  height: 100% !important;

  position: relative;
  top: 0px;
  left: 3px;
  bottom: 0;
  right: 0;
  width: 100%;
  //height: 100%;
  background-image: url("../../image/svg/WatchAnswer/WatchMen.svg");
  background-size: contain;
  background-repeat: no-repeat;
  //@include media-max(1400) {
  //  height: calc(100% - 21px);
  //}
  @include media-max($xs-break) {
    left: 1px;
  }
}


.cardWrapContact {
  position: absolute;
  top: 0;
  width: 338px;
  height: 618px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  max-width: 100%;
  max-height: 625px;

  border-radius: 20px;

  @include media-max(1440) {
    width: 311px;
    height: 569px;
  }
  @include media-max($lg-break) {
    width: 244px;
    height: 446px;
  }
  @include media-max($xs-break) {
    width: 224px;
    height: 407px;
  }
}
.contentWrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}

.cardContainerContact {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../image/svg/WatchAnswer/WatchMen.svg");
  background-size: contain;
  background-repeat: no-repeat;


  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: -3px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../image/svg/WatchAnswer/WatchVioletPhone.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;

  }

  //}

  .blur {
    position: absolute;
    z-index: 0;
    top: 1px;
    left: -3px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 96%;
    border-radius: 27px;
    backdrop-filter: blur(15px);
    &__none {
      display: none;
    }
  }

  .cardLeft {
    position: absolute;
    left: -16em;
    @include media-max($lg-break) {
      left: -17em;
    }
    @include media-max($md-break) {
      left: -19em;
    }

  }

  .cardRight {
    position: absolute;
    right: -16em;
    @include media-max($lg-break) {
      right: -17em;
    }
    @include media-max($md-break) {
      right: -19em;
    }
  }

  .cardWrap {
    width: 338px;
    height: 618px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 1;
    max-width: 100%;
    max-height: 625px;
    position: relative;
    border-radius: 20px;
    background-image: url("../../image/svg/WatchAnswer/WatchVioletPhone.svg");
    background-repeat: no-repeat;
    background-size: cover;
    backdrop-filter: blur(4px);

    @include media-max(1440) {
      width: 311px;
      height: 569px;
    }
    @include media-max($lg-break) {
      width: 244px;
      height: 446px;
    }
    @include media-max($xs-break) {
      width: 224px;
      height: 407px;
    }
  }

  .cardLeft {
    position: absolute;
    left: -16em;
    @include media-max($lg-break) {
      left: -11em;
    }
    @include media-max($md-break) {
      left: -13em;
    }
    @include media-max($sm-break) {
      left: -10em;
    }
    @include media-max($xs-break) {
      left: -12em;
    }
  }

  .cardRight {
    position: absolute;
    right: -16em;
    @include media-max($lg-break) {
      right: -11em;
    }
    @include media-max($md-break) {
      right: -13em;
    }
    @include media-max($sm-break) {
      right: -10em;
    }
    @include media-max($xs-break) {
      right: -12em;
    }
  }

  .cardContainer {
    position: relative;
    top: 0px;
    left: 3px;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../image/svg/WatchAnswer/WatchMen.svg");
    background-size: contain;
    background-repeat: no-repeat;
    @include media-max($xs-break) {
      left: 1px;
    }
  }

  .contentBox {
    position: relative;
    z-index: 7;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    //justify-content: center;
  }

  .iconBox {
    margin-top: 65px;
    width: 130px;
    height: 130px;
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    //box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
    @include media-max($lg-break) {
      width: 84px;
      min-height: 84px;
      height: 84px;
      margin-top: 25px;
    }
  }

  .circleIcon {
    min-width: 112px;
    height: 112px;
    fill: $white;
    @include media-max($lg-break) {
      min-width: 74px;
      height: 74px;
    }
  }

  .nameBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 28px;
    margin-bottom: 60px;
    @include media-max($lg-break) {
      margin-top: 24px;
      margin-bottom: 35px;
    }
  }

  .name {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
    color: #FFFFFF;
    @include media-max($lg-break) {
      font-size: 22px;
    }
  }

  .starsLine {
    min-width: auto;
    height: 28px;
    @include media-max($lg-break) {
      height: 17px;
    }
  }

  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 28px;
    @include media-max($lg-break) {
      padding: 0 14px;
    }
  }

  .infoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 21px;

    .infoIcon {
      min-width: 30px;
      height: 38px;
      @include media-max($lg-break) {
        min-width: 30px;
        height: 30px;
      }
    }

    .linkIconWrap {
      min-width: 31px;
      height: 31px;
      background-color: #7685FE;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;

      .linkIcon {
        min-width: 18px;
        height: 8px;
      }
    }

    .contactBlock {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      margin-left: 21px;
      min-width: 165px;
      max-width: 100%;
      @include media-max($lg-break) {
        margin-left: 3px;
        min-width: calc(100% - 68px);
      }


      .titleInfo {
        font-family: "Roboto", sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.3;
        text-align: center;
        color: #FFFFFF;
      }

      .textInfo {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 1.3;
        color: #FFFFFF;
      }
    }
  }
}
