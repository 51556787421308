@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  @include media-max($lg-break) {
    margin-bottom: 60px;
  }
  @include media-max($sm-break) {
    margin-bottom: 0;
    align-items: flex-start;
  }
}

.cardWrap {
  width: 338px;
  height: 475px;
  position: relative;
  z-index: 3;
  max-width: 100%;
  max-height: 625px;
  text-align: left !important;
  min-height: 200px;

  @include media-max($lg-break) {
    width: 312px;
    height: 430px;
  }
  @include media-max($sm-break) {
    width: 280px;
    height: 430px;
  }
  @include media-max($l-break) {
    width: 250px;
  }
}

.cardBg {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  //position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  //overflow: hidden;
  background: $white;
  background-size: cover;
  backdrop-filter: blur(20px);
}

.activeEdit {
  transform: rotateY(0deg);
}

.cardBgSide {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("../../image/svg/ShareAsker/shareBgWhite.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100% + 61px);
  background-position: center;
}

.cardLeft {
  position: absolute;
  //width: 338px;
  //height: 475px;
  left: -16em;
  @include media-max($lg-break) {
    left: -17em;
  }
  @include media-max($md-break) {
    left: -18em;
  }
  @include media-max($sm-break) {
    left: -17em;
  }
  @include media-max($xs-break) {
    left: -16em;
    display: none;
  }
}

.cardRight {
  position: absolute;
  right: -16em;
  @include media-max($lg-break) {
    right: -17em;
  }
  @include media-max($md-break) {
    right: -18em;
  }
  @include media-max($sm-break) {
    right: -17em;
  }
  @include media-max($xs-break) {
    right: -16em;
    display: none;
  }
}

.cardContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 27px;
  transition: 900ms;
  @include media-max($lg-break) {
    padding: 20px;
  }
  @include media-max($sm-break) {
    padding: 15px;
  }
}

.questionBlockHide {
  background: linear-gradient(196.51deg, rgba(123, 139, 255, 0.95) 3.21%, rgba(84, 104, 255, 0.95) 94.01%);
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 27px;
  transition: 900ms;
}


.rotate {
  transform: rotate(-315deg);
  position: absolute;
  bottom: -20px;
  z-index: -1;
  left: calc(50% - 18px);
}

.logoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 20px;


  .letterIcon {
    min-width: 125px;
    height: 120px;
  }

}

.createLogo {
  min-width: 122px;
  height: 122px;
  position: absolute;
  top: 18px;
  left: 0;
  @include media-max($lg-break) {
    min-width: 100px;
    height: 100px;
    position: absolute;
    top: 18px;
    left: 6px;
  }
}

.questionBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  margin-bottom: 10px;
  //width: 231px;
}


.textBox {
  max-width: 150px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  //padding: 11px 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 5px);
  color: rgba(96, 111, 129, 0.9);
  margin-right: 24px;

  input {
    display: flex;
    width: 100%;

    max-width: 100%;
    font-size: 15px;
    line-height: 1;
    text-align: start;
    //color: $darkText;
    font-weight: 400;
    border: none;
    background: none;
    appearance: none;
    padding: 3px 5px 3px 0;
    color: rgba(96, 111, 129, 0.9);
    min-height: 65px;

    &:focus {
      outline: none;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: rgba(52, 67, 86, 0.9);
  text-align: start;

  @include media-max($lg-break) {
    font-size: 18px;
  }
}

.titleSmall {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  text-align: start;
  color: rgba(96, 111, 129, 0.9);
}

.text {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.1;
  text-align: start;
  @include media-max($lg-break) {
    font-size: 13px;
  }
}


.timesBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 11px;
  line-height: 2;
  min-width: 42px;

  span {
    color: #ADB8FA;

    &.active {
      color: #7685FE;
    }
  }
}


.questionBlock {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: calc(100% - 85px);
  @include media-max($lg-break) {
    width: calc(100% - 70px);
  }
}


.iconWrap {
  @include btn-reset();
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7685FE;
  min-width: 31px;
  height: 31px;
  margin-right: 5px;
  box-shadow: 0px 8.12698px 20.3175px rgba(84, 104, 255, 0.3);
  border-radius: 7.31429px;

  &:hover, &:active {
    opacity: 0.8;
    transition: $transition;
  }
}

.editIcon {
  min-width: 16px;
  height: 15px;
}


.textInfo {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.7;
  color: $darkText;
  width: 196px;
  opacity: .8;
  @include media-max($lg-break) {
    line-height: 1.4;
  }
}


.buttonBox {
  margin-top: 5px;
  width: 100%;
}

.buttonStylePublich {
  background: #00D9CD;
  width: 100%;
}

.plusIconBox {
  position: absolute;
  top: calc(50% - 16px);
  right: 14px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #44BCB6;
  border-radius: 50%;

  .shareIcon {
    min-width: 11px;
    height: 9px;
  }
}


.contentBlock {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 7px;
}

.contentBlockContainer {
  height: 300px;
  overflow: auto;
  padding: 5px;

  //overflow-x: hidden;
}

.iconsBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 44px;
  align-items: center;
  font-family: $main-font;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #ADB8FA;
}

.iconClock {
  width: 52px;
  min-width: 52px;
  height: 49px;
  position: relative;
  stroke: #ADB8FA;
  @include media-max($lg-break) {
    width: 40px;
    min-width: 40px;
    height: 40px;
  }
}


.questionItemEmpty {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  width: 100%;
  padding-left: 21px;
  min-height: 74px;
  //margin-bottom: 5px;
  //background: #FAFAFA;
  background: rgba(255, 255, 255, 0.95);
  filter: drop-shadow(0px 3px 19px rgba(60, 128, 209, 0.09));
  @include media-max($lg-break) {
    min-height: 62px;
  }
}

.textEdit {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-weight: 400;
  font-size: 15px;
  text-align: start;
  line-height: 1.1;
  color: #344356;
  height: 100%;
  opacity: 0.8;
  margin-left: 10px;
  width: 100%;

  @include media-max($l-break) {
    font-size: 13px;
  }
}

.iconWrapCheck {
  background-color: #00D9CD;
  //margin-right: 10px;
}

.checkIcon {
  min-width: 12px;
  height: 10px;
}

.questionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  width: 100%;
  //padding: 0 15px 0 12px;
  margin-bottom: 5px;
  min-height: 50px;
  padding: 5px 0 5px 5px;
  @include media-max($lg-break) {
    min-height: 40px;
  }

  &.questionItemSolid {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 3px 19px rgba(60, 128, 209, 0.09);
    min-height: 50px;
  }

  &.questionItemDash {
    background: rgba(173, 184, 250, 0.1);
    border: 1px dashed rgba(173, 184, 250, 0.5);
    box-sizing: border-box;
    min-height: 65px;
    padding-left: 21px;
  }
}

.rotateT {
  position: absolute;
  bottom: -22px;
  z-index: -1;
  fill: #fafbfe;
  left: calc(50% - 12px);
  width: 36px;
  height: 24px;
}
