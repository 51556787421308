@import "../../scss/settings";
@import "../../scss/mixins";


.containerBurgerMenu {
  //position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //width: 100%;
  //max-width: 100%;
  padding: 0 31px 0 43px;
}



.tabBoxBurgerMenu {
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  margin-left: 20px;
  @include media-max($md-break) {
    margin-left: 20px;

  }
}

.linkWrapBurgerMenu {
  height: 100px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  text-decoration: none;
  color: $white;


  .itemLinkBurgerMenu {
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;

  }

  &:hover, &.active{
    color: $themeColorSimilar;
  }

}

.joinWrapBurgerMenu {
  background-image: linear-gradient(90deg, #01EBDE, #5468FF);
  border-radius: 22px;
  padding: 1px;
  overflow: hidden;
  text-decoration: none;
  margin-top: 30px;
  .joinLinkBurgerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 45px;
    background-color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    border-radius: 22px;
    color: rgba(84, 104, 255, 0.85);
    line-height: 1;
    @include media-max($xs-break) {
      width: 120px;
    }
  }

  &:hover {
    .joinLink {
      background-color: transparent;
      color: #fff;
    }
  }
}
.burgerMenu{
  //height: 100%;
  background-color: $themeColor;
}
