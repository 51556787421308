@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 10px;
  width: 205px;
  height: 46px;
  padding: 2px;
  background: rgba(243, 245, 249, 0.8);
  box-shadow: 0px 17.7273px 18.9091px rgba(0, 20, 170, 0.03);
  border-radius: 26px;
  margin-bottom: 45px;
  margin-top: 10px;
  @include media-max($sm-break) {
    margin-bottom: 25px;
  }
  @include media-max($xs-break) {
    margin-bottom: 20px;
    margin-top: 5px;
  }
}
.infoIcon {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 53px;
  height: 52px;
}


.infoText {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  height: 100%;
  width: calc(100% - 53px);
  font-size: 12px;
  line-height: 1.2;
  color: $darkText;

}

.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  //height: 850px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 200vmin;
}

.cardWrap {
  perspective: 200vmin;
}

.cardWrapContact {
  width: 333px;
  height: 494px;
  display: flex;
  align-items: center;
  justify-content: center;
  //overflow: hidden;
  z-index: 1;
  max-width: 100%;
  max-height: 494px;
  position: relative;
  border-radius: 20px;


  @include media-max($lg-break) {
    width: 318px;
    height: 581px;
    position: relative;
  }
}

@keyframes rotate {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.triangleWrap {
  height: calc(100% - 30px);
  width: 100%;
  overflow: hidden;
}

.cardContainerContact {
  //position: absolute;
  z-index: 3;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../image/svg/Frame44.svg");
  background-size: contain;
  background-repeat: no-repeat;
  box-shadow: 0px 21px 45px -112px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 30px);
    overflow: hidden;
    background: linear-gradient(223.59deg, rgba(100, 118, 255, 0.7) 56.78%, rgba(84, 104, 255, 0.553) 103.48%);
    backdrop-filter: blur(10px);
    border-radius: 29px;
    fill: red;

    @include media-max($lg-break) {
      height: calc(100% - 50px);
    }
  }
}

.triangleBgIcon {
  width: calc(100% - 3px);
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0px;
  right: -12px;
  @include media-max($lg-break) {
    top: -41px;
  }
}


.contentBox {
  position: relative;
  z-index: 7;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  border-radius: 28px;
}

.searchBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 37px 48px;
  @include media-max($lg-break) {
    bottom: 26px;
  }
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 12px;
}

.buttonBox {
  margin-top: 5px;
  width: 100%;
}

.questionOption {
  min-width: 22px;
  height: 23px;
  margin-right: 4px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 30px;
}

.searchFilled {
  height: 48px;
  border-radius: 12px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  background: $white;
  align-items: center;
  justify-content: center;
  text-align: center;

  input {
    min-height: 48px;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    padding: 0 15px 0;
    display: flex;
    align-items: center;
    text-align: center;

    &::placeholder {
      text-align: center;
      font-size: 16px;
      font-family: $main-font;
      font-weight: 400;
      line-height: 1;
      color: #BABABA;
    }
  }
}
.searchBtn {
  cursor: pointer;
  position: relative;
  right: 8px;
  width: 30px;
  height: 30px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #5468ff;
  box-shadow: 0px 6.2px 15.5px rgba(84, 104, 255, 0.3);
  border-radius: 7px;
  color: #ffffff;
  font-size: 18px;

  .serchIcon {
    width: 12px;
    height: 12px;
  }
}
.arrowDown{
  position: absolute;
}

.rotateT {
  width: 60px;
  height: 30px;
  fill:#5b6efd;
  position: absolute;
  bottom: 3px;
  left: calc(50% - 29px);
  @include media-max($lg-break) {
    bottom: 22px;
  }
}
