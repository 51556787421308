@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  @include media-max($lg-break) {
    margin-bottom: 60px;
  }
  @include media-max($sm-break) {
    margin-bottom: 0;
    align-items: flex-start;
  }
}

.cardWrap {
  width: 338px;
  height: 475px;
  position: relative;
  z-index: 3;
  max-width: 100%;
  max-height: 625px;
  text-align: left !important;
  min-height: 200px;
  @include media-max($lg-break) {
    width: 312px;
    height: 430px;
  }
  @include media-max($sm-break) {
    width: 280px;
    height: 430px;
  }
  @include media-max($l-break) {
    width: 250px;
  }

}

.cardBg {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  background: linear-gradient(196.51deg, rgba(123, 139, 255, 0.95) 3.21%, rgba(84, 104, 255, 0.95) 94.01%);
  background-size: cover;
  backdrop-filter: blur(20px);
}

.cardBgSide {
  background-image: url("../../image/svg/ShareAsker/shareBgWhite.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100% + 61px);
  background-position: center;
}

.cardLeft {
  position: absolute;
  //width: 338px;
  //height: 475px;
  left: -16em;
  @include media-max($lg-break) {
    left: -17em;
  }
  @include media-max($md-break) {
    left: -18em;
  }
  @include media-max($sm-break) {
    left: -17em;
  }
  @include media-max($xs-break) {
    left: -16em;
    display: none;
  }

}

.cardRight {
  position: absolute;
  right: -16em;
  @include media-max($lg-break) {
    right: -17em;
  }
  @include media-max($md-break) {
    right: -18em;
  }
  @include media-max($sm-break) {
    right: -17em;
  }
  @include media-max($xs-break) {
    right: -16em;
    display: none;
  }
}

.cardContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  //align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 27px;
  @include media-max($lg-break) {
    padding: 20px;
  }
  @include media-max($sm-break) {
    padding: 15px;
  }
}


.rotate {
  //transform: rotate(-315deg);
  position: absolute;
  bottom: -54px;
  z-index: -1;
  left: calc(50% - 23px);
}

.rotateT {
  position: absolute;
  bottom: -22px;
  z-index: 100;
  fill: #5a6efa;
  left: calc(50% - 12px);
  width: 36px;
  height: 24px;
}

.leftT {
  animation: kek;
  animation-duration: .5s;
  animation-fill-mode: forwards;
}

@keyframes kek {
  0%, 75%{
    opacity: 1;

  }
  100%{
    opacity: 0;
  }
}


.questionOption {
  min-width: 22px;
  height: 23px;
  margin-right: 4px;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 26px;
}

.buttonBox {
  margin-top: 5px;
  width: 100%;
}

.buttonStylePublich {
  background: $themeColorBase;
  width: 100%;
}

.arrowTopGreen {
  transform: rotate(-90deg);
  fill: #44BCB6;
}

.plusIconBox {
  position: absolute;
  top: calc(50% - 16px);
  right: 14px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #44BCB6;
  border-radius: 50%;

  .shareIcon {
    min-width: 16px;
    height: 16px;
  }
}

.btnBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.contentBox {
  @include btn-reset();
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    opacity: 0.8;
    transition: $transition;

  }
}

.optionBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

}

.iconWrap {
  min-width: 31px;
  height: 31px;
  background-color: #ADB8FA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;

  .linkIcon {
    min-width: 17px;
    height: 17px;
  }
}

.menIcon {
  min-width: 34px;
  height: 32px;
  background-position: center;
  margin-right: 15px;
}

.textBox {
  display: flex;
  align-items:  flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}


.title {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  color: $white;
}

.text {
  display: flex;
  font-family: $main-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  color: $white;
  text-align: start;
  //margin-bottom: 17px;
}


.btnClose {
  @include btn-reset();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 5;
  right: 15px;
  top: 18px;

  &:hover {
    opacity: .8;
    ransition: $transition
  }
}

.closeIcon {
  min-width: 14px;
  height: 14px;
}

.deactivateBlock {
  display: flex;
  //flex-direction: column;
  width: 100%;

}

.switchWrap {
  //width: 48px;
  //height: 32px;
  //background-image: url("../../image/svg/SwitchIcon.svg");
  //background-repeat: no-repeat;
  //background-size: cover;
}


.acdcToogler {
  width: 68px;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  left: 8px;
  cursor: pointer;

  input {
    position: relative;
    z-index: 3;
    opacity: 0;
    width: 36px;
    min-height: 32px;
    cursor: pointer;
  }

  span {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 36px;
    height: 8px;
    background-color: #d4dce7;
    border-radius: 5px;
    transition: 0.3s ease;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
      width: 21px;
      height: 21px;
      border: 7px solid #ffffff;
      border-radius: 50%;
      background-color: transparent;
      z-index: 1;
      transition: 0.3s ease;
    }
  }

  input:checked ~ span {
    background-color: #00d9cd;
    &:after {
      left: 15px;
    }
  }
}

.optionalContainer {
  //width: 338px;
  //height: 475px;
  perspective: 200vmin;
  width: 338px;
  height: 475px;
  @include media-max($lg-break) {
    width: 312px;
    height: 430px;
  }
  @include media-max($sm-break) {
    width: 280px;
    height: 430px;
  }
  @include media-max($l-break) {
    width: 250px;
  }
}
