@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-BoldItalic.eot');
    //src: url('/fonts/HKGrotesk-BoldItalic.eot');
    src: url('/fonts/HKGrotesk-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-BoldItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-BoldItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-BoldItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-BoldItalic.svg#HKGrotesk-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('/fonts/HKGrotesk-BoldLegacy.eot');
    src: url('/fonts/HKGrotesk-BoldLegacy.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-BoldLegacy.woff2') format('woff2'),
    url('/fonts/HKGrotesk-BoldLegacy.woff') format('woff'),
    url('/fonts/HKGrotesk-BoldLegacy.ttf') format('truetype'),
    url('/fonts/HKGrotesk-BoldLegacy.svg#HKGrotesk-BoldLegacy') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Bold.eot');
    src: url('/fonts/HKGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-Bold.woff2') format('woff2'),
    url('/fonts/HKGrotesk-Bold.woff') format('woff'),
    url('/fonts/HKGrotesk-Bold.ttf') format('truetype'),
    url('/fonts/HKGrotesk-Bold.svg#HKGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Medium.eot');
    src: url('/fonts/HKGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-Medium.woff2') format('woff2'),
    url('/fonts/HKGrotesk-Medium.woff') format('woff'),
    url('/fonts/HKGrotesk-Medium.ttf') format('truetype'),
    url('/fonts/HKGrotesk-Medium.svg#HKGrotesk-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Light Legacy';
    src: url('/fonts/HKGrotesk-LightLegacyItalic.eot');
    src: url('/fonts/HKGrotesk-LightLegacyItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-LightLegacyItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-LightLegacyItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-LightLegacyItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-LightLegacyItalic.svg#HKGrotesk-LightLegacyItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-MediumItalic.eot');
    src: url('/fonts/HKGrotesk-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-MediumItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-MediumItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-MediumItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-MediumItalic.svg#HKGrotesk-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('/fonts/HKGrotesk-BoldLegacyItalic.eot');
    src: url('/fonts/HKGrotesk-BoldLegacyItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-BoldLegacyItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-BoldLegacyItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-BoldLegacyItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-BoldLegacyItalic.svg#HKGrotesk-BoldLegacyItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('/fonts/HKGrotesk-LegacyItalic.eot');
    src: url('/fonts/HKGrotesk-LegacyItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-LegacyItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-LegacyItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-LegacyItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-LegacyItalic.svg#HKGrotesk-LegacyItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Light Legacy';
    src: url('/fonts/HKGrotesk-LightLegacy.eot');
    src: url('/fonts/HKGrotesk-LightLegacy.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-LightLegacy.woff2') format('woff2'),
    url('/fonts/HKGrotesk-LightLegacy.woff') format('woff'),
    url('/fonts/HKGrotesk-LightLegacy.ttf') format('truetype'),
    url('/fonts/HKGrotesk-LightLegacy.svg#HKGrotesk-LightLegacy') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Italic.eot');
    src: url('/fonts/HKGrotesk-Italic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-Italic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-Italic.woff') format('woff'),
    url('/fonts/HKGrotesk-Italic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-Italic.svg#HKGrotesk-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Light.eot');
    src: url('/fonts/HKGrotesk-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-Light.woff2') format('woff2'),
    url('/fonts/HKGrotesk-Light.woff') format('woff'),
    url('/fonts/HKGrotesk-Light.ttf') format('truetype'),
    url('/fonts/HKGrotesk-Light.svg#HKGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-LightItalic.eot');
    src: url('/fonts/HKGrotesk-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-LightItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-LightItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-LightItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-LightItalic.svg#HKGrotesk-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-SemiBold.eot');
    src: url('/fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
    url('/fonts/HKGrotesk-SemiBold.woff') format('woff'),
    url('/fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
    url('/fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Legacy';
    src: url('/fonts/HKGrotesk-RegularLegacy.eot');
    src: url('/fonts/HKGrotesk-RegularLegacy.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-RegularLegacy.woff2') format('woff2'),
    url('/fonts/HKGrotesk-RegularLegacy.woff') format('woff'),
    url('/fonts/HKGrotesk-RegularLegacy.ttf') format('truetype'),
    url('/fonts/HKGrotesk-RegularLegacy.svg#HKGrotesk-RegularLegacy') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Regular.eot');
    src: url('/fonts/HKGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-Regular.woff2') format('woff2'),
    url('/fonts/HKGrotesk-Regular.woff') format('woff'),
    url('/fonts/HKGrotesk-Regular.ttf') format('truetype'),
    url('/fonts/HKGrotesk-Regular.svg#HKGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-SemiBoldItalic.eot');
    src: url('/fonts/HKGrotesk-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-SemiBoldItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-SemiBoldItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-SemiBoldItalic.svg#HKGrotesk-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Medium Legacy';
    src: url('/fonts/HKGrotesk-MediumLegacyItalic.eot');
    src: url('/fonts/HKGrotesk-MediumLegacyItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-MediumLegacyItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-MediumLegacyItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-MediumLegacyItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-MediumLegacyItalic.svg#HKGrotesk-MediumLegacyItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk Medium Legacy';
    src: url('/fonts/HKGrotesk-MediumLegacy.eot');
    src: url('/fonts/HKGrotesk-MediumLegacy.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-MediumLegacy.woff2') format('woff2'),
    url('/fonts/HKGrotesk-MediumLegacy.woff') format('woff'),
    url('/fonts/HKGrotesk-MediumLegacy.ttf') format('truetype'),
    url('/fonts/HKGrotesk-MediumLegacy.svg#HKGrotesk-MediumLegacy') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk SemiBold Legacy';
    src: url('/fonts/HKGrotesk-SemiBoldLegacy.eot');
    src: url('/fonts/HKGrotesk-SemiBoldLegacy.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-SemiBoldLegacy.woff2') format('woff2'),
    url('/fonts/HKGrotesk-SemiBoldLegacy.woff') format('woff'),
    url('/fonts/HKGrotesk-SemiBoldLegacy.ttf') format('truetype'),
    url('/fonts/HKGrotesk-SemiBoldLegacy.svg#HKGrotesk-SemiBoldLegacy') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HK Grotesk SmBold Legacy';
    src: url('/fonts/HKGrotesk-SemiBoldLegacyItalic.eot');
    src: url('/fonts/HKGrotesk-SemiBoldLegacyItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/HKGrotesk-SemiBoldLegacyItalic.woff2') format('woff2'),
    url('/fonts/HKGrotesk-SemiBoldLegacyItalic.woff') format('woff'),
    url('/fonts/HKGrotesk-SemiBoldLegacyItalic.ttf') format('truetype'),
    url('/fonts/HKGrotesk-SemiBoldLegacyItalic.svg#HKGrotesk-SemiBoldLegacyItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
