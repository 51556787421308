@import "../../scss/settings";
@import "../../scss/mixins";

.dashboardContainer {
  width: 100%;
  height: 100%;
  perspective: 200vmin;
  height: calc(100% - 64px);
}

.createContainer {
  display: flex;
  align-items: center;
  text-align: left !important;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  padding: 0 20px 0 20px;
  border-radius: 40px;
  //@include media-max($md-break) {
  // align-items: flex-start;
  //}
}

.grayBg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
}

.bgContainer {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  width: 514px;
  // min-height: 200px;
  padding-bottom: 20px;
  padding-top: 30px;
  height: 100%;
}

.contantWrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 625px;
  //background-color: #F3F5F9D9;
  border-radius: 40px;
  padding: 15px 0px 15px 15px;
  background: rgba(243, 245, 249, 0.75);
  backdrop-filter: blur(25px);
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.03);
  @include media-max($md-break) {
    margin-bottom: 25px;
    max-height: 525px;
  }
  @media screen and (max-height: 660px) {
    // max-height: 525px;
  }
}

.topBox {
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.buttonBox {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgb(255 255 255 / 40%);
}

.buttonStyle {
  background: #5468ff;
  border-radius: 16px;
  margin-top: 25px;
  width: 315px;
  text-align: center;
  letter-spacing: 1.008px;
  text-transform: uppercase;
  filter: drop-shadow(0px 10px 25px rgba(84, 104, 255, 0.3));
  @include media-max($md-break) {
    width: 215px;
    padding: 0;
    border-radius: 10px;
    height: 48px;
    font-size: 14px;
  }
  @include media-max($xs-break) {
    width: 215px;
    height: 46px;
    border-radius: 10px;
    font-size: 14px;
    padding: 0;
  }
}

.buttonItem {
  font-family: $main-font;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.75px;
  width: 100%;
  max-width: 100%;
  background: #00d9cd;
  height: 34px;
  box-shadow: 0px 7.4803px 18.7007px rgba(84, 104, 255, 0.3);
  text-align: center;
  border-radius: 8px;
}

.shareButtonItem {
  background-color: #b9c3fb;
  box-shadow: 0px 7.96143px 19.9036px rgba(84, 104, 255, 0.3);
}

.plusIconBox {
  position: absolute;
  top: calc(50% - 11px);
  right: 4px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #44bcb6;
  border-radius: 50%;

  .shareIcon {
    min-width: 21px;
    height: 23px;
    position: relative;
    margin-left: 1px;
  }
}

.shareIconBox {
  background-color: #9eaaee;

  .shareIcon {
    min-width: 10px;
    height: 12px;
    position: relative;
    margin-left: 1px;
  }
}

.linePhone {
  min-width: 148px;
  height: 5px;
  margin-top: 19px;
  margin-bottom: 6px;
  fill: #000;
  opacity: 0.1;
}

.arrowBtn {
  display: flex;
  position: absolute;
  min-width: 30px;
  height: 30px;
  top: 14px;
  fill: #324add;
  right: 14px;
  @include media-max($md-break) {
    min-width: 24px;
    height: 24px;
    top: 12px;
    right: 10px;
  }
  @include media-max($xs-break) {
    min-width: 24px;
    height: 24px;
    top: 12px;
  }
}

.logo {
  height: auto;
  width: 62px;
}

.headBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
  height: 31px;
  margin-top: -16px;
  @include media-max($xs-break) {
    margin-top: 10px;
  }
}

.tabBox {
  @include btn-reset();
  width: 89px;
  height: 100%;
  background: #ffffff;
  opacity: 0.85;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  //font-family: $fontRoboto;
  padding-top: 0px;
  font-family: $main-font;
  font-size: 12px;
  line-height: 1;
  color: #344356;
  letter-spacing: 1.285px;
  text-transform: uppercase;
  margin-right: 2px;
  border: 0;
  letter-spacing: 0.994px;
  line-height: normal;

  &:hover {
    opacity: 0.8;
    transition: $transition;
    color: #344356;
    font-weight: 700;
  }
}

.active {
  color: #344356;
  font-weight: 700;
  box-shadow: 0 12px 19px rgba(60, 128, 209, 0.2);
}

.tabLeft {
  border-radius: 11px 0 0 11px;
}

.tabright {
  border-radius: 0 11px 11px 0;
  margin-right: 0;
}

.menuWBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: 12px 25px 0 12px;
  opacity: 0.6;
}

.burgerBtn {
  @include btn-reset();
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;

  &:hover {
    opacity: 0.8;
    transition: $transition;
  }
}

.burgerIcon {
  height: 13px;
  width: 20px;
  fill: #adb8fa;
}

.row {
  display: block;
  // height: 300px;
  // //height: 400px;
  // max-height: 409px;
  overflow-y: auto;
  overflow-x: hidden;
  transition: $transition;
  //@include media-max($md-break) {
  //  height: 300px;
  //}
  @media screen and (max-height: 660px) {
    // height: 200px;
  }
  @include media-max($sm-break) {
    // height: 230px;
  }
}
.rowAnswers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;
  // max-height: 409px;
  padding-right: 12px;
  //@include media-max($md-break) {
  //  flex-direction: column;
  //  flex-wrap: nowrap;
  //}
  @include media-max($sm-break) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }
}

.cardWrap {
  display: block;
  padding: 0 3px;
  min-height: 100px;
  margin-bottom: 10px;
  border-radius: 10px;
  width: calc(50% - 1px);
  filter: drop-shadow(0px 6px 12px rgba(0, 16, 138, 0.05));

  //@include media-max($md-break) {
  //  width: calc(50% - 1px);
  //}
  @include media-max($lg-break) {
    width: calc(50% - 1px);
  }
  @include media-max($sm-break) {
    width: calc(50% - 1px);
  }
  //@include media-max($sm-break) {
  //  width: calc(50% - 1px);
  //}
  @include media-max($l-break) {
    width: 100%;
  }
  //@media (min-width: 760px) and (max-width: 880px) {
  //  width: 100%
  //}
}

.answersContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  //min-height:120px;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: $white;
  padding: 12px;
}

.questionItem {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border-radius: 14px;
  width: 100%;
  padding: 0 15px 0 12px;
  margin-bottom: 5px;
}

.createLogo {
  min-width: 57px;
  height: 50px;
  //position: relative;
  position: absolute;
  top: 10px;
  left: 5px;
}

.questionBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  width: calc(100% - 52px);
  position: relative;
  z-index: 1;
  //width: 100%;
  height: 100%;
}

.questionBox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  //width: 231px;
}

.textBox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  //padding: 11px 14px;
  width: 100%;
  //width: calc(100% - 5px);
  color: #000000;
  margin-top: 5px;
  opacity: 0.8;
  overflow: hidden;
}

.title {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.2;
  color: black;
}

.text {
  display: flex;
  align-items: center;
  font-family: $main-font;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0.8;
}

.addAskerBtn {
  @include btn-reset();
  background: rgba(255, 255, 255, 0.6);
  opacity: 0.2;
  border: 3px dashed #8594e5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  min-height: 100px;

  //&:hover {
  //  background-color: #8594E6;
  //  transition: $transition;
  //}
}

.plusIcon {
  min-width: 26px;
  height: 26px;
  fill: #dadada;

  &:hover {
    background-color: $white;
    transition: $transition;
  }
}

//.contentBox {
//  @include btn-reset();
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//
//  @include btn-reset();
//  //display: flex;
//  align-items: center;
//  justify-content: center;
//  background: #7685FE;
//  min-width: 31px;
//  height: 31px;
//  box-shadow: 0px 8.12698px 20.3175px rgba(84, 104, 255, 0.3);
//  border-radius: 7.31429px;
//
//  &:hover, &:active {
//    opacity: 0.8;
//    transition: $transition;
//  }
//
//  &:hover {
//    opacity: 0.8;
//    transition: $transition;
//
//  }
//
//  .optionBlock {
//    display: flex;
//    align-items: center;
//    justify-content: space-between;
//    width: 100%;
//    margin-bottom: 10px;
//
//  }
//
//  .iconWrap {
//    //min-width: 31px;
//    //height: 31px;
//    background-color: #ADB8FA;
//    //display: flex;
//    //justify-content: center;
//    //align-items: center;
//    //border-radius: 50%;
//    //margin-right: 15px;
//
//    .linkIcon {
//      min-width: 17px;
//      height: 17px;
//      position: relative;
//      margin-left: 1px;
//    }
//
//
//    position: absolute;
//    top: calc(50% - 11px);
//    right: 4px;
//    width: 22px;
//    height: 22px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    background: #44BCB6;
//    border-radius: 50%;
//
//    //.shareIcon {
//    //  min-width: 21px;
//    //  height: 23px;
//    //  position: relative;
//    //  margin-left: 1px;
//    //}
//  }
//
//  .textBox {
//    display: flex;
//    align-items:  flex-start;
//    flex-direction: column;
//    justify-content: center;
//    width: 100%;
//  }
//
//
//  .title {
//    display: flex;
//    align-items: center;
//    font-family: $main-font;
//    font-weight: 700;
//    font-size: 18px;
//    line-height: 1.2;
//    color: $white;
//  }
//}
.rotateAfterLogin {
  transition: none;
  animation: rotate 0.8s cubic-bezier(0, -0, 0.4, 1);
  animation-fill-mode: forwards;
}

@keyframes rotate {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.fixHeigthAuto {
  @include media-max($md-break) {
    height: auto !important;
  }
}

.menuOne {
  color: #000;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  background: rgb(255 255 255 / 70%);
  padding-top: 15px;
}
