@import "../../scss/settings";
@import "../../scss/mixins";

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 0 31px 0 43px;
  //margin-bottom: 30px;
  @include media-max($lg-break) {
    padding: 0 24px;
  }
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  height: 55px;
  width: 75px;
  @include media-max($md-break) {
    height: 60px
  }
}

.tabBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 20px;
  @include media-max($lg-break) {
    margin-left: 20px;
    display: none;

  }
  @include media-max($md-break) {
    margin-left: 20px;
    display: none;

  }
}

.linkWrap {
  height: 30px;
  display: inline-flex;
  align-items: center;
  //margin-right: 40px;
  text-decoration: none;
  color: #ABABAB;
   &:not(:last-child){
     margin-right: 30px;
   }
   &:nth-last-child(2){
     margin-right:15px;
     margin-left: 15px;
   }
  @include media-max(1550) {
    margin-right: 3vw;
  }
  @include media-max($lg-break) {
    margin-right: 2vw;
  }

  .itemLink {
    //font-family: 'Work Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;


  }

  &:hover, &.active{
    color: $themeColor;
  }

}

.joinWrap {
  background-image: linear-gradient(90deg, #01EBDE, #5468FF);
  border-radius: 22px;
  padding: 1px;
  overflow: hidden;
  text-decoration: none;

  .joinLink {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 49px;
    padding: 0 10px;
    height: 28px;
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    border-radius: 22px;
    color: #ABABAB;
    @include media-max($xs-break) {
      width: 120px;
    }
  }

  &:hover {
    .joinLink {
      background-color: transparent;
      color: #fff;
    }
  }
}
