@import "../../scss/settings";
@import "../../scss/mixins";

.settingsContainer {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.settingsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(90, 109, 254, 0.2);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;

  .card {
    margin-top: 30px;
    background: rgba(88, 108, 255, 0.95);
    box-shadow: 0px 12px 19px rgba(60, 128, 209, 0.09);
    padding: 40px 13px 30px;
    border-radius: 10px;

    .option {
      color: white;
      display: flex;
      margin-top: 20px;
      align-items: flex-start;
      position: relative;

      &__icon {
        width: 30px;
        height: 30px;
        background-image: url("../../image/svg/CircleContact.svg");
        background-size: cover;
        background-position: center;
        border-radius: 50%;
      }

      &__info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 20px;
          line-height: 18px;
        }

        &__text {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }

      &_disabled {
        opacity: .5;
      }
    }
  }

  .settingsTitle {
    margin-top: 40px;
    display: flex;
    align-items: flex-end;
    margin-left: -40px;

    & h6 {
      color: white;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      margin-left: 5px;
    }

    &__icon {
      width: 30px;
      height: 30px;
      background-image: url("../../image/svg/CircleContact.svg");
      background-size: cover;
      background-position: center;
    }
  }

  .accountWrapper {
    .accountIcon {
      width: 120px;
      height: 120px;
      border: 9px solid white;
      border-radius: 50%;
      margin: 0 80px;
      //background-image: url("../../image/accountSettings/pexels-photo-2332413.jpg");
      background-position: center;
      background-size: cover;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.2);
    }

    .accountInfo {

    }

  }

  .functionalityWrapper {
    width: 100%;
    padding-top: 0;

    .option {

    }
  }

  .someComponent {
    width: 100%;
    height: 30px;
  }


  .arrow {
    border: solid white;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(-45deg);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
  }
}
