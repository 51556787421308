@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  @include media-max($sm-break) {
    padding-top: 10px;
  }
}

.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  perspective: 200vmin;
  @include media-max($lg-break) {
    margin-bottom: 60px;
  }
  @include media-max($sm-break) {
    margin-bottom: 0;
    align-items: flex-start;
  }
}

.withAbsoluteAndTop {
  top: 0 !important;
  position: absolute !important;
}

.cardWrap {
  width: 338px;
  height: 475px;
  position: relative;
  z-index: 3;
  max-width: 100%;
  max-height: 625px;
  text-align: left !important;
  min-height: 200px;

  @include media-max($lg-break) {
    width: 312px;
    height: 430px;
  }
  @include media-max($sm-break) {
    width: 280px;
    height: 430px;
  }
  @include media-max($l-break) {
    width: 250px;
  }

}

.activeShare {
  transform: rotateY(1deg);
}


.menuEditShare{
  transform: rotateY(1deg);
}

@keyframes rotateAnimationShare {
  0%{
    transform: rotateY(180deg);
  }
  1%{
    transform: rotateY(181deg);
  }
  100%{
    transform: rotateY(0deg);
  }
}

.overflow {
  overflow: auto !important;
}

.cardBg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  z-index: 3;
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(196.51deg, rgba(123, 139, 255, 0.95) 3.21%, rgba(84, 104, 255, 0.95) 94.01%);
  background-size: cover;
  backdrop-filter: blur(20px);
}

.cardBgSide {
  background-image: url("../../image/svg/ShareAsker/shareBgWhite.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100% + 61px);
  background-position: center;
}

.cardLeft {
  position: absolute;
  //width: 338px;
  //height: 475px;
  left: -16em;
  @include media-max($lg-break) {
    left: -17em;
  }
  @include media-max($md-break) {
    left: -18em;
  }
  @include media-max($sm-break) {
    left: -17em;
  }
  @include media-max($xs-break) {
    left: -16em;
    display: none;
  }

}

.cardRight {
  position: absolute;
  right: -16em;
  @include media-max($lg-break) {
    right: -17em;
  }
  @include media-max($md-break) {
    right: -18em;
  }
  @include media-max($sm-break) {
    right: -17em;
  }
  @include media-max($xs-break) {
    right: -16em;
    display: none;
  }
}

.cardContainer {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 27px;
  @include media-max($lg-break) {
    padding: 20px;
  }
  @include media-max($sm-break) {
    padding: 15px;
  }
}


.rotate {
  //transform: rotate(180deg);
  position: absolute;
  bottom: -30px;
  z-index: -1;
  left: calc(50% - 20px);
}
.rotateArrowBtn{
  width: 100%;
  height: 100%;
}

.logoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 20px;
  @include media-max($lg-break) {
    margin-bottom: 14px;
    margin-top: 15px;
  }

  .letterIcon {
    min-width: 125px;
    height: 120px;
    @include media-max($lg-break) {
      min-width: 95px;
      height: 92px;
    }
  }
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 13px;
}

.shareLink {
  min-width: 15px;
  height: 16px;
  margin-right: 8px;
}

.text {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 17px;
}

.buttonBox {
  margin-top: 5px;
  width: 100%;
}

.buttonStylePublich {
  background: $themeColorBase;
  width: 100%;
}

.arrowTopGreen {
  transform: rotate(-90deg);
  fill: #44BCB6;
}

.plusIconBox {
  position: absolute;
  top: calc(50% - 16px);
  right: 14px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #44BCB6;
  border-radius: 50%;

  .shareIcon {
    min-width: 16px;
    height: 16px;
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: space-between;
  width: 100%;
  padding: 0 30px;
}

.inputBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 0 12px;
  margin-bottom: 10px;
  background-color: $white;
  box-shadow: 0px 10px 25px rgba(84, 104, 255, 0.3);
  border-radius: 10px;
  height: 50px;
}

.inputBoxEmail {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}


.textBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 5px);
  color: rgba(96, 111, 129, 0.9);

  input {
    display: flex;
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    //color: $darkText;
    color: #898A8D!important;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    border: none;
    background: none;
    appearance: none;
    min-height: 10px;
    padding: 3px 5px 3px 0;

    &::placeholder {
      //width: 100%;
      text-align: center!important;
      font-size: 14px;
      line-height: 1;
      color: #898A8D!important;
    }

    //&:disabled {
    //  color: #898A8D!important;
    //  &::placeholder {
    //    //width: 100%;
    //    text-align: center!important;
    //    font-size: 14px;
    //    line-height: 1;
    //    color: #898A8D!important;
    //  }
    //}

    &:focus {
      outline: none;
    }
  }
}


.emailIcon {
  width: 18px;
  height: 14px;
  fill: #ADB8FA;
}


.title {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1;
}

.linkIconWrap {
  @include btn-reset();
  min-width: 31px;
  height: 31px;
  background-color: $themeColorBase;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6.2px 15.5px rgba(84, 104, 255, 0.3);
  border-radius: 7px;

  &:hover {
    opacity: 0.8;
    transition: $transition;
  }

  .linkIcon {
    min-width: 18px;
    height: 8px;
  }
}

.emailIcon {
  width: 18px;
  height: 14px;
  fill: #ADB8FA;
}


.btnClose {
  @include btn-reset();
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 5;
  right: 28px;
  top: 24px;

  &:hover {
    opacity: .8;
    ransition: $transition
  }
}

.closeIcon {
  min-width: 14px;
  height: 14px;
}

.shareEmailModal {
  max-width: 420px;
  min-height: 420px;
}

.shareIconBox {
  position: absolute;
  top: calc(50% - 14px);
  right: 0;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #324add;
  border-radius: 50%;

  .shareIcon {
    min-width: 16px;
    height: 31px;
    position: relative;
    margin-left: 1px;
  }
}

.rotateT {
  position: absolute;
  bottom: -22px;
  z-index: -1;
  fill: #586cfe;
  left: calc(50% - 19px);
  width: 36px;
  height: 24px;
}
