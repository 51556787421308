@import "settings";
@import "mixins";
@import "animation";
@import "import-fonts";
//@import "bootstrap";
@import "modal";
//@import "slige";

* {
  outline: none !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  min-height: 100%;
  min-width: 375px;
  height: 100%;
  scroll-behavior: smooth;

  //@include media-max($xxs-break) {
  //  min-width: 320px;
  //}

  &.no-scroll {
    body {
      overflow: hidden;
    }
  }
}

body {
  overflow-x: hidden;
  height: 100%;
  min-width: 375px;
  color: #000;
  font-family: $main-font !important;
  font-weight: bold;
  scroll-behavior: smooth;
  @include media-max($xxs-break) {
    min-width: 320px;
  }

  .container {
    max-width: 100%;
    padding: 0;
    width: 1440px;
  }

  a {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}

#root {
  min-height: 100vh;
  height: 100%;
  color: #000;
  font-family: $main-font;
  font-weight: bold;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  @include media-max($sm-break) {
    height: auto;
  }
}

h1 {
  font-size: 29px;
  padding: 50px 0 10px 0;
  @include media-max($sm-break) {
    font-size: 25px;
  }
  @include media-max($xs-break) {
    font-size: 23px;
  }
}

h3 {
  @include media-max($xs-break) {
    font-size: 18px;
  }
}

.row {
  margin: 0;
  //height: 100%;
}

.text-truncate {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s;
  color: $themeColorText !important;
  //font-size: 16px!important;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: $themeColorText; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

/*
  Removing arrows on the right of the input type number
*/
// for firefox
input[type="number"] {
  -moz-appearance: textfield !important;
}

// for others
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.col-padding {
  padding-left: 6px;
}

.activeTyped {
  transition: 0.3s ease;
  overflow: hidden;
  padding: 0 5px;
  font-weight: 700;
  font-size: 56px;
  background-color: #b6fffb;
  line-height: 0;
}
.activeTypedViolet {
  transition: 0.3s ease;
  overflow: hidden;
  padding: 0 5px;
  font-weight: 700;
  font-size: 56px;
  background-color: #adb8fa;
  line-height: 0;
}

h2 {
  font-size: 41px;

  span {
    color: #0f0222;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 1.3;
  }
}

.continue-btn {
  @include btn-reset();
  position: relative;
  white-space: nowrap;
  transition: $transition;
  box-shadow: 0px 10px 25px rgba(54, 74, 226, 0.25);
  font-family: $main-font;
  height: 58px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  text-decoration: none;
  padding: 0 25px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  backdrop-filter: blur(25px);
  background: #3d56f0;
  border-radius: 18px;
  text-transform: uppercase;
  color: $white;

  &:hover {
    opacity: 0.8;
    transition: $transition;
  }

  &:disabled {
    opacity: 0.5;
  }
}
.continue-btn > span {
  padding: 0 100px;
}
.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}
.fade-out {
  animation: fadeOut ease 3s;
  -webkit-animation: fadeOut ease 3s;
  -moz-animation: fadeOut ease 3s;
  -o-animation: fadeOut ease 3s;
  -ms-animation: fadeOut ease 3s;
}

.ease-in-effect {
  animation: dissolveInLeft 0.5s ease-in-out;
  -webkit-animation: dissolveInLeft 0.5s ease-in-out;
  //animation: dissolveInLeft 1s ease-in-out;
  //-webkit-animation: dissolveInLeft 1s ease-in-out;
}

.ease-out-effect {
  animation: dissolveInRight 1s ease-in-out;
  //animation-delay: 10s;
  -webkit-animation: dissolveInRight 1s ease-in-out;
  //animation: dissolveInRight 0.5s ease-in-out;
  //-webkit-animation: dissolveInRight 0.5s ease-in-out;
}

// style for login/signup containers --------------------------------------------------//
.container-login {
  //display: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 70px 20px;
  perspective: 200vmin;
  animation: 1s ease 0.3s normal forwards 1 fadein;
  -webkit-animation: 1s ease 0.3s normal forwards 1 fadein;
  opacity: 0;
  @media screen and (max-height: 690px) {
    padding: 35px 20px;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contentWrap-login {
  z-index: 1;
  max-width: 100%;
  width: 514px;
  height: 100%;
  max-height: 625px;
  display: flex;
  position: relative;
  border-radius: 40px;
  background: rgba(84, 104, 255, 0.85);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background-image: url("../image/login/Men.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    //background-size: 100% 100%;
  }
}

.contentWrap-login.start-rotate {
  transform: rotateY(-90deg);
  transition: transform 400ms 0s cubic-bezier(0.63, -0.32, 0.4, 1.39);
}

.cardWrap.customRotate {
  transition: none !important;
  animation: rotate 0.4s cubic-bezier(0.63, -1, 0.4, 1);
  animation-fill-mode: forwards;
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(-90deg);
  }
}

.contentBox-login {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 40px;
  background: rgba(84, 104, 255, 0.85);
  box-shadow: 0px 4px 54px rgba(84, 104, 255, 0.3);
  backdrop-filter: blur(5px);
}

// button ---------------------//

.button-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.arrow-btn {
  display: flex;
  position: absolute;
  min-width: 30px;
  height: 30px;
  top: 14px;
  right: 14px;
  fill: #324add;
}

.blur-box {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bm-burger-button {
  display: none;
  position: absolute;
  width: 36px;
  height: 30px;
  top: 15px;
  right: 35px;
  fill: rgba(84, 104, 255, 0.85);

  @include media-max($lg-break) {
    display: block;
    top: 25px;
  }
  @include media-max($sm-break) {
    top: 15px;
  }
}

/***************************** WATCH ANSWER : START *****************************/
.watch-answer-box {
  width: 100%;
}
.wth-ans-outer-slider {
  width: 100%;
}
.wth-ans-outer-single-slide {
  padding-bottom: 30px;

  .border-bx {
    position: relative;
    padding: 10px;
    width: 100%;
    max-width: 338px;
    height: 610px;
    margin: auto;
    background-image: linear-gradient(
      239.12deg,
      #5468ff -90.88%,
      #f8f9ff 261.21%
    );
    border-radius: 30px;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-width: 30px 30px 0 30px;
      border-color: #a1acff transparent transparent transparent;
      border-style: solid;
      z-index: 1;
      border-radius: 0px 0px 0px 0px;
      transition: 1s ease;
      transform: translate(0, 0);
      visibility: hidden;
      opacity: 0;
    }
  }
  &.slick-current {
    .border-bx {
      &:before {
        transform: translate(0, 99%);
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .screen {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 30px;
    overflow: hidden;
  }
  .slick-list,
  .slick-track {
    height: 100%;
  }

  .img-vid {
    width: 100%;
    height: 100%;

    img,
    video,
    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }
  .head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 15px;

    a {
      cursor: pointer;
      display: inline-flex;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #5468ff;
      color: #ffffff;

      span {
        margin: auto;
      }
    }
  }

  .cont {
    position: absolute;
    bottom: 43px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    padding: 15px 15px;

    .star-rate {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 5px;

      .material-icons {
        color: #adb8fa;
        opacity: 0.5;
        padding: 0 5px;
        cursor: pointer;

        &:hover ~ .material-icons {
          color: #ffffff;
          opacity: 1;
        }
      }

      &:hover,
      &:focus {
        .material-icons {
          color: #ffffff;
          opacity: 1;
        }
      }

      .star-checked {
        color: #ffffff;
        opacity: 1;
      }
    }

    p {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      opacity: 0.8;
    }
  }
}
.modile-screen-border {
  position: relative;
  padding: 10px;
  //width: 100%;
  max-width: 338px;
  width: 338px;
  height: 610px;
  margin: auto;
  background-image: linear-gradient(
    239.12deg,
    #5468ff -90.88%,
    #f8f9ff 261.21%
  );
  border-radius: 30px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    background-color: #a1acff;
    z-index: 1;
    border-radius: 5px;
    transition: 1s ease;
    transform: translate(0, 18px) rotate(45deg);
  }

  .screen {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 30px;
    overflow: hidden;
    z-index: 1;
  }

  .head {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px 15px;
  }

  .img-vid {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;
    }
  }

  .cont {
    position: absolute;
    //bottom: 130px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    color: #ffffff;
    text-align: center;
    padding: 15px 15px;
  }

  &.get-ready {
    .cont {
      bottom: 0px;
    }
  }
}
.asker-option-page {
  .status-info-modal {
    position: relative;
    height: 438px;
  }
}
.status-info-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-image: linear-gradient(
    216.78deg,
    rgba(100, 118, 255, 1) 62.09%,
    rgba(84, 104, 255, 0.632) 103.06%
  );
  backdrop-filter: blur(4px);
  z-index: 9;
  padding: 35px 28px;
  transition: 0.3s ease;
  display: none;

  visibility: hidden;
  opacity: 0;
  transform: translate(0, 50%);

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    background-color: #8d9aff;
    z-index: 1;
    border-radius: 5px;
    transition: 1s ease;

    visibility: hidden;
    opacity: 0;
    transform: translate(0, 0) rotate(45deg);
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    //background-image: url("../images/status-info-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top right;
    z-index: 1;

    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0.3s;
  }

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
    display: block;

    &:before {
      visibility: visible;
      opacity: 0.8;
      transform: translate(0, 19px) rotate(45deg);
    }

    &:after {
      width: 90%;
      height: 90%;
    }
  }

  .inner-bx {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    z-index: 2;
  }

  .close,
  .close-bttn {
    cursor: pointer;
    position: absolute;
    top: -20px;
    right: -15px;
    width: 50px;
    height: 50px;
    display: inline-flex;

    span,
    a {
      margin: auto;
      color: #ffffff;
    }
  }
  &.account-setting-modal {
    border-radius: 40px;
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
    padding: 25px 28px !important;
    .home-bottom-slide {
      bottom: -15px !important;
    }

    &:before {
      visibility: hidden;
    }

    .inner-bx {
      display: block;
    }

    .close,
    .close-bttn {
      top: -15px;
      right: auto;
      left: -15px;
    }

    .list {
      margin-bottom: 50px;
    }
  }
  .head {
    margin-bottom: 30px;
    width: 100%;
    text-align: center;

    h4 {
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
  .log-out {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    z-index: 1;

    a,
    input,
    button {
      border: none;
      display: inline-block;

      background-color: transparent;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;

      &:hover,
      &:focus {
        text-decoration: underline;
        color: #00d9cd;
      }
    }
  }
  .list {
    width: 100%;

    ul {
      li {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: #ffffff;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          color: #ffffff;
          opacity: 0.8;

          &:hover,
          &:focus {
            // color: #00D9CD;
            opacity: 1;
          }
        }

        .icon {
          display: inline-flex;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #adb8fa;

          span {
            margin: auto;
          }
        }

        .text {
          width: calc(100% - 38px);
          padding-left: 18px;
          text-align: left;

          h4 {
            font-size: 18px;
            font-weight: bold;
            text-transform: capitalize;
          }
          p {
            font-size: 12px;
            font-weight: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
          }
        }

        &.has-toogler {
          .text {
            width: calc(100% - 74px);
          }
        }

        .acdc-toogler {
          position: relative;

          input {
            position: relative;
            z-index: 3;
            opacity: 0;
            width: 36px;
            height: 20px;
            cursor: pointer;
          }

          span {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 36px;
            height: 8px;
            background-color: #d4dce7;
            border-radius: 5px;
            transition: 0.3s ease;

            &:after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: -8px;
              width: 21px;
              height: 21px;
              border: 8px solid #ffffff;
              border-radius: 50%;
              background-color: transparent;
              z-index: 1;
              transition: 0.3s ease;
            }
          }

          input:checked ~ span {
            background-color: #00d9cd;
            &:after {
              left: 15px;
            }
          }
        }
      }
    }
  }
  .label-rating {
    width: 100%;
  }
  .label {
    width: 100%;
    margin-bottom: 26px;

    ul {
      li {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        .icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #adb8fa;
        }

        &.red .icon {
          background-color: #ff0000;
        }
        &.green .icon {
          background-color: #00ff0a;
        }
        &.yellow .icon {
          background-color: #ffe600;
        }

        .text {
          width: calc(100% - 16px);
          padding-left: 20px;
          color: #ffffff;

          h4 {
            font-size: 18px;
            font-weight: bold;
            text-transform: capitalize;
          }
          p {
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .star-rate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    text-align: center;

    .material-icons {
      color: rgba(173, 184, 250, 0.5);
      opacity: 0.5;
      padding: 0 5px;

      &:nth-child(-n + 3) {
        color: #ffffff;
        opacity: 1;
      }
    }
  }
}
.wth-ans-inner-slider {
  position: relative;
  top: 0;
  //top: 1px;
  border-radius: 20px;
  height: 100%;
  @include media-max(1440) {
    //width: 311px;
    width: 261px;
    top: 0;
    height: 100%;
  }
  @include media-max($lg-break) {
    width: 244px;
  }
  @include media-max($xs-break) {
    //width: 200px;
    height: 100%;
    top: 0;
  }

  .slick-dots {
    position: absolute;
    z-index: 0;
    border-radius: 0px 0px 26px 26px;
    left: 0;
    right: 0;
    bottom: 4px;
    width: 100%;
    display: flex;
    padding: 15px 10px 20px;

    @include media-max(1440) {
      bottom: 0;
    }
    @include media-max($lg-break) {
      padding: 20px 10px 14px;
    }
    @include media-max($xs-break) {
      //bottom: 4-;
    }

    li {
      flex: 1 0 0;
      padding: 0 5px;
      list-style-type: none;

      button {
        opacity: 0.6;
        background: #585858;
        position: relative;
        width: 100%;
        height: 8px;
        border: none;
        border-radius: 30px;
        //background-color: #ffffff;
        overflow: hidden;
        font-size: 0;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0;
          height: 100%;
          background-color: #00d9cd;
          transition-duration: 0s;
          transition-timing-function: linear;
        }
      }
      &.finished {
        button {
          &:after {
            width: 100%;
            transition-duration: 0s;
            transition-delay: 0s;
          }
        }
      }
    }
  }
}

.slick-list {
  height: 583px;
  @include media-max(1440) {
    //height: 540px;
    height: 457px;
  }
  @include media-max($lg-break) {
    height: 421px;
  }
  @include media-max($xs-break) {
    //height: 346px;
  }
}
.slick-slide {
  height: 583px;
  @include media-max(1440) {
    //height: 540px;
    height: 457px;
  }
  @include media-max($lg-break) {
    height: 421px;
  }
  @include media-max($xs-break) {
    //height: 346px;
  }

  .img-vid {
    height: 583px;
    @include media-max(1440) {
      //height: 540px;
      height: 457px;
    }
    @include media-max($lg-break) {
      height: 421px;
    }
    @include media-max($xs-break) {
      //height: 346px;
    }

    div {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.wth-ans-outer-single-slide {
  &.slick-current {
    .wth-ans-inner-slider {
      .slick-dots {
        li {
          &.slick-active {
            button {
              &:after {
                // width: 100%;
                animation-name: statusProgress;
                animation-duration: 15s;
                animation-fill-mode: both;
                animation-iteration-count: 1;
                animation-timing-function: linear;
              }
            }
          }
        }
      }
    }
  }
}
.promotionVideoSlide {
  .slick-dots {
    display: flex !important;
    li {
      &.slick-active {
        button {
          opacity: 1 !important;
          &:after {
            // width: 100%;
            animation-name: statusProgress;
            animation-duration: 15s;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-timing-function: linear;
          }
        }
      }
    }
  }
}
@keyframes statusProgress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.wth-ans-inner-single-slide {
  position: relative;
  height: 100%;
}
