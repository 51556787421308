@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.infoBlockHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  left: 10px;
  padding-right: 6px;
  min-width: 205px;
  height: 46px;
  padding: 2px 13px 2px 2px;
  background: rgba(243, 245, 249, 0.8);
  box-shadow: 0px 17.7273px 18.9091px rgb(0 20 170 / 3%);
  border-radius: 26px;
  margin-top: 10px;
  @include media-max($md-break) {
    top: -10px;
  }
  @include media-max($xs-break) {
    //top: -24px;
  }
}

.infoIcon {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 53px;
  height: 52px;
}


.infoText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 100%;
  width: calc(100% - 53px);
  font-size: 14px;
  line-height: 1.2;
  color: #000;

  .smallText {
    font-size: 12px;
    opacity: 0.6;
  }

}
.contentContainer {
  position: relative;
  z-index: 5;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  //height: 850px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 200vmin;
  @include media-max($lg-break) {
    margin-bottom: 60px;
  }
  @include media-max($sm-break) {
    margin-bottom: 0;
    align-items: flex-start;
  }
}

.cardWrapContact {
  width: 335px;
  height: 494px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  max-width: 100%;
  max-height: 494px;
  position: relative;
  border-radius: 20px;
  @include media-max($lg-break) {
    width: 333px;
    height: 581px
  }
  @include media-max($sm-break) {
    width: 300px;
    height: 446px;
  }
  @include media-max($xs-break) {
    width: 303px;
  }
}

.cardContainerContact {
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../image/svg/SearchBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 21px 45px -112px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 26px);
    background: linear-gradient(223.59deg, rgba(100, 118, 255, 0.7) 56.78%, rgba(84, 104, 255, 0.553) 103.48%);
    backdrop-filter: blur(10px);
    border-radius: 29px;
    @include media-max($lg-break) {
      height: calc(100% - 29px);
    }
    @include media-max($xs-break) {
      height: calc(100% - 23px);
    }
  }
}

.triangleBgIcon {
  width: 88%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: -44px;
  right: 0;
  @include media-max($lg-break) {
    top: -41px;
  }
}


.contentBox {
  position: relative;
  z-index: 7;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: calc(100% - 24px);
  overflow: hidden;
  align-items: center;
  border-radius: 26px;
  //justify-content: center;
}

.searchBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 100%;
  height: 100%;
  justify-content: center;
  position: relative;
  z-index: 3;
  padding: 37px 48px;
}

.titleBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 12px;
}

.buttonBox {
  margin-top: 5px;
  width: 100%;
}

.questionOption {
  min-width: 22px;
  height: 23px;
  margin-right: 4px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: $main-font;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.2;
  color: $white;
  margin-bottom: 30px;
}

.searchFilled {
  height: 48px;
  border-radius: 12px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  input {
    min-height: 48px;
    border-radius: 12px;
    width: 100%;

    &::placeholder {
      text-align: center;
      font-size: 16px;
      font-family: $main-font;
      font-weight: 400;
      line-height: 1;
      color: #BABABA;
    }
  }
}
.searchBtn {
  position: relative;
  right: 8px;
  width: 30px;
  height: 30px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #5468ff;
  box-shadow: 0px 6.2px 15.5px rgba(84, 104, 255, 0.3);
  border-radius: 7px;
  color: #ffffff;
  font-size: 18px;

  .serchIcon {
    width: 12px;
    height: 12px;
  }
}

.firstRotate {
  transition: none !important;
  animation: rotate .8s cubic-bezier(1, -0, 0.4, 1.39);
  animation-fill-mode: forwards;
}

@keyframes rotate {
  from {
    transform: rotateY(90deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.progressIcon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  min-width: 225px;
  height: 228px;
  box-shadow: 1px 7px 15px -85px rgba(89, 215, 207, 0.1);
}

.progressBox {
  width: 225px;
  height: 228px;
  z-index: 1;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;

  .infoBox {
    z-index: 2;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2px;
  }


  .persent {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 1;
    color: #626262;
  }
  .textProgressive {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 17px;
    line-height: 1;
    color: #939AA3;
  }
}
