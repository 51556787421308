@import "../../scss/settings";
@import "../../scss/mixins";

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  padding: 15px 0 0 15px;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.infoBlock {
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  width: 205px;
  height: 46px;
  z-index: 100;
  top: 20px;
  background: rgba(243, 245, 249, 0.8);
  box-shadow: 0px 17.7273px 18.9091px rgba(0, 20, 170, 0.03);
  border-radius: 26px;
  margin-bottom: 37px;
  //@include media-max($sm-break) {
  //  margin-bottom: 25px;
  //}
  //@include media-max($xs-break) {
  //  margin-bottom: 20px;
  //  margin-top: 5px;
  //}
}

.infoBlockSmall {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 2px;
  box-shadow: 0px 17.7273px 18.9091px rgba(0, 20, 170, 0.03);
  z-index: 2;
  background: transparent;
  width: 65px;
  height: 13px;
  border-radius: 0;
}

.infoIcon {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 53px;
  height: 52px;
}

.infoIconSmall {
  position: relative;
  top: 2px;
  left: -5px;
  min-width: 13px;
  height: 10px;
}

.infoText {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  height: 100%;
  width: calc(100% - 53px);
  font-size: 12px;
  line-height: 1.2;
  color: $darkText;
}
.answersSpace {
  width: 100%;
  height: 100px;
}
.infoTextSmall {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  height: 100%;
  width: calc(100% - 53px);
  line-height: 1.2;
  font-size: 3px;
  color: $white;
}

.answersWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}
.row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-self: center;
  height: 100%;
  overflow: auto;
  width: 100%;
  margin: 0 -8px;
  @include media-max($lg-break) {
    width: 100%;
  }
  @include media-max($md-break) {
    width: 100%;
  }
  @include media-max($sm-break) {
    width: 100%;
  }
}

.cardWrap {
  display: block;
  padding: 0 8px;
  margin-bottom: 10px;
  width: 20%;
  @include media-max($lg-break) {
    width: 25%;
  }
  @include media-max($md-break) {
    width: 33.33%;
  }
  @include media-max($sm-break) {
    width: 20%;
  }
  @include media-max($xs-break) {
    width: 33.33%;
  }
}

.answersContainer {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.answerCard {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 211px;
  width: 110px;
  z-index: 1;
  border-radius: 8px;
  background-image: url("../../image/svg/BgCardFull.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.dropcstm {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.9);
  mix-blend-mode: normal;
  padding: 0;
  width: calc(100% - 24px);

  > div {
    margin-left: -24px;
    background: transparent;
    margin-top: 5px;
    width: calc(100% + 26px);
    border: 0;
    a {
      font-size: 14px;
      display: flex;
      align-items: center;
      background: rgb(84 104 255 / 10%);
      padding: 2px 6px;
      border-radius: 36px;
      color: #fff !important;
      margin-bottom: 4px;
      font-weight: 400;
      &:hover {
        background: rgba(84, 104, 255, 0.3);
        backdrop-filter: blur(40px);
      }
      &:focus {
        background: rgb(84 104 255 / 60%);
      }
      &:active {
        background: rgb(84 104 255 / 60%);
      }
      &:visited {
        background: rgb(84 104 255 / 60%);
      }
      &:focus-within {
        background: rgb(84 104 255 / 60%);
      }
      &:focus-visible {
        background: rgb(84 104 255 / 60%);
      }
      span {
        width: calc(100% - 24px);
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 0px;
      }
    }
  }
  a {
    font-size: 14px;
  }
  button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.9);
    mix-blend-mode: normal;
    padding: 0;
    background-color: transparent;
    &:hover {
      color: #fff;
    }
    &:focus {
      box-shadow: none;
    }
    span {
      width: 262px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (min-width: 1200px) and (max-width: 1320px) {
        width: 202px;
      }
    }
  }
}
.dropwrapper {
  background: rgba(84, 104, 255, 0.05);
  backdrop-filter: blur(40px);
  border-radius: 36.9543px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px 1px 1px;
  transition: ease-in-out 0.5s;
  &:hover {
    background: rgb(84 104 255 / 30%);
  }
  &:focus {
    background: rgb(84 104 255 / 60%);
  }
  &:active {
    background: rgb(84 104 255 / 60%);
  }
  &:visited {
    background: rgb(84 104 255 / 60%);
  }
  &:focus-within {
    background: rgb(84 104 255 / 60%);
  }
  &:focus-visible {
    background: rgb(84 104 255 / 60%);
  }

  .infoIcon {
    width: 24px;
    height: 24px;
    min-width: initial;
    top: 0;
    left: 0;
  }
  @include media-max($md-break) {
    width: 100%;
  }
  @include media-max($sm-break) {
    width: 100%;
  }
}

.infoBlock {
  width: auto;
  position: relative;
  top: 0;
  background: transparent;
  box-shadow: none;
  margin-bottom: 15px;
  width: 100%;
  padding-right: 15px;
  @include media-max($md-break) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include media-max($sm-break) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }
}
.dateTime {
  margin-left: 6px;
  input {
    backdrop-filter: blur(40px);
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.9);
    mix-blend-mode: normal;
    padding: 0;
    background: rgba(84, 104, 255, 0.05);
    min-height: 28px;
    text-align: center;
    padding-right: 8px;
    width: 124px;
    padding: 0 10px;
  }
}

// ::-webkit-calendar-picker-indicator {
//   filter: invert(1);
// }

.searchinfo {
  display: flex;
  align-items: center;
  position: relative;
  text-align: center;
  input {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    background: rgba(84, 104, 255, 0.05);
    backdrop-filter: blur(30px);
    border-radius: 30px;
    min-height: 28px;
    padding: 0 10px;
    width: 145px;
    text-align: left;
    padding-right: 30px;
  }
  svg {
    position: absolute;
    right: 10px;
    top: 6px;
  }
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  text-align: center;
}
input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
  text-align: center;
}
input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  text-align: center;
}
input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
  text-align: center;
}

.settingIc {
  color: #000;
  background: rgba(84, 104, 255, 0.05);
  width: 29px;
  height: 29px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
.aright {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include media-max($md-break) {
    margin-top: 3px;
  }
  @include media-max($sm-break) {
    margin-top: 3px;
  }
  @include media-max($xs-break) {
    margin-top: 3px;
  }
}

@media (min-width: 1200px) and (max-width: 1320px) {
}
