@import "../../scss/settings";
@import "../../scss/mixins";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 112px 20px 19px;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  @include media-max($md-break) {
    padding: 112px 20px 19px;
  }

  @media screen and (max-width: 767px),
  screen and (max-height: 690px) {
    padding: 25px 20px 19px;
  }
  @include media-max($xs-break) {
    padding: 20px 20px 19px;
  }

}

.arrowBtnWrap {
  @include btn-reset();
  position: absolute;
  top: 32px;
  left: 31px;
  display: flex;
  width: 32px;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.8;
    transition: $transition;
  }
}

.arrowBack {
  width: 20px;
  min-width: 20px;
  height: 19px;
}

.logoBox {
  display: flex;
  width: 315px;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  .questionIcon {
    width: 34px;
    height: 34px;
  }

  .logo {
    width: 115px;
    height: 42px;
  }
}

.loginForm {
  width: 315px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
  justify-content: center;
}

.inputBox {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .codeBox {
    position: relative;
    background-color: transparent;
    height: 65px;
    width: 100%;

    input {
      position: absolute;
      left: 0;
      color:transparent;
      top: 0;
      bottom: 0;
      width: calc(100% + 85px);
      height: 100%;
      //color: $darkText;
      font-size: 25px;
      font-weight: bold;
      z-index: 1;
      //letter-spacing: 70px;
      padding: 0 24px;
      caret-color: transparent;
      background-color: transparent!important;
      border: none;
      font-family: "Roboto", sans-serif;
    }

    .codeItems {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      span {
        caret-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        background-color: #ffffff;
        border-radius: 15px;
        box-shadow: 0px 12px 19px rgb(60 128 209 / 8%);
        color: $darkText;
        font-size: 25px;
        font-weight: bold;
        border: none;
        font-family: "Roboto", sans-serif;
        //z-index: 1;
        &.opacity{
          opacity: .3;
        }
      }
    }
  }
}

.emailIcon {
  width: 18px;
  height: 14px;
}
.menIcon {
  width: 15px;
  height: 17px;
}

.lockIcon {
  width: 15px;
  height: 18px;
}

.title {
  display: flex;
  width: 100%;
  max-width: 100%;
  color: $white;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 700;
  font-family: $main-font;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.greenText {
  color: $themeColorBase;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    opacity: .8;
    transition: $transition
  }
}

.text {
  display: flex;
  width: 100%;
  max-width: 100%;
  color: $white;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 400;
  font-family: $main-font;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  &.paddingTop {
    margin-top: 15px;
    text-decoration: none;

    &:hover {
      opacity: .8;
      transition: $transition
    }
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
}

.linePhone {
  min-width: 148px;
  height: 5px;
  margin-top: 11px;
  fill: $white;
  opacity: .2
}

.arrowBtn {
  isplay: flex;
  position: absolute;
  min-width: 30px;
  height: 30px;
  top: 14px;
  right: 14px;
  fill: #324ADD;
}

// create account -----------------//

.inputPhoneWrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $white;
  height: 64px;
  max-width: 100%;
  border-radius: 15px!important;

  .iconSelectWrap {
    min-width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: url("../../image/svg/FlagUs.svg");
    background-size: contain;
    //background-size: cover;
    background-repeat: no-repeat;
    margin: 0 0 0 15px;
    background-position: center top;
  }

  .selectBox {
    border-right: 1px solid $darkText;
    opacity: .2;
    width: 50px;
    display: flex;
    align-items: center;
    height: 30px;
  }

input {
  width: calc(100% - 110px);
  display: flex;
  justify-content: space-between;
  //width: calc(100% - 60px);
  max-width: 100%;
  min-height: 65px;
  font-size: 14px;
  line-height: 1;
  text-align: start;
  color: $darkText;
  font-weight: 400;
  font-family: $main-font;
  padding-right: 15px;
  border: none;
  background: none;
  appearance: none;
  height: auto;
  &.withPrefix {
    padding-left: 0;
  }
  text-overflow: ellipsis;
  @include media-max($xs-break) {
    font-size: 15px;
    @-moz-document url-prefix()
    {
      font-size:14px;
      //padding-top:30px;
    }
  }

  &::placeholder {
    color: #979797;
    //opacity: 0;
    font-weight: 400;
    text-align: start;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: #FAFAFA;
    color: #A0B2BE;
    border-radius: 5px;
  }
}

}
